import React, { useEffect, useRef } from "react";
import { ProbillDetails } from "../../../app/data/stop-details/models";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "../../../components/accordion/accordion";
import { AccordionRef } from "../../../components/accordion/types";
import { CompletePickupButton } from "../../pickups/completePickupButton";
import { AddressModel } from "../../../app/data/common/models";

interface ShipperAccordionItemProps {
  manifestNumber: number;
  stopNumber: number;
  children: any;
  id?: string;
  probills: ProbillDetails[];
  name: string;
  completed: boolean;
  address: AddressModel;
}

export const ShipperAccordionItem: React.FC<ShipperAccordionItemProps> = ({
  stopNumber,
  children,
  address,
  ...props
}) => {
  const accordionRef = useRef<AccordionRef>(null);

  useEffect(() => {
    if (props.completed) accordionRef.current?.unCollapse();
  }, [props.completed]);

  let itemsCount = props.probills.reduce((sum, val) => sum + val.items, 0);
  return (
    <AccordionItem
      className={`xgs-consignee-accordion ${
        (props.completed) ? "xgs-consignee-accordion--delivered" : ""
      }`}
    >
      <AccordionItemButton
        ref={accordionRef}
        className={`xgs-consignee-accordion__button ${
          (props.completed)
            ? "xgs-consignee-accordion__button--delivered"
            : ""
        }`}
      >
        <div className="xgs-consignee-accordion__button__text">
          <div className="xgs-consignee-accordion__button__text__consignee">
            {props.name}
          </div>
          <div className="xgs-consignee-accordion__button__text__number">
            {props.probills.length} Probill
            {props.probills.length > 1 ? "s" : ""}, {itemsCount} Item
            {itemsCount > 1 ? "s" : ""}
          </div>
          {address.additionalAddressLine && <div className="xgs-consignee-accordion__button__text__address">
            <div className="xgs-consignee-accordion__button__text__address__label">
              Additional Address Line:
            </div>
            <div className="xgs-consignee-accordion__button__text__address__value">
              {address.additionalAddressLine}
            </div>
          </div>}
        </div>
        <div className="xgs-consignee-accordion__button__actions">
          {(!props.completed) && (
            <CompletePickupButton
              manifestNumber={props.manifestNumber}
              stopNumber={stopNumber}
              pickupNumber={props.probills[0].probill.toString()}
            />
          )}
        </div>
      </AccordionItemButton>
      <AccordionItemPanel className="xgs-consignee-accordion__panel">
        {children}
      </AccordionItemPanel>
    </AccordionItem>
  );
}
